import React, { useState, useContext, useEffect } from "react";
import FirstStepAccount from "./createAccountComps/firstStep";
import SecondStepAccount from "./createAccountComps/secondStep";
import ThirdStepAccount from "./createAccountComps/ThirdStep";
import { Link } from "gatsby";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";

function CreateAccountNew() {
  const [currentStep, setCurrentStep] = useState(1);

  const [langData, setlangData] = useState();

  const { data } = useFetchLanguages();

  useEffect(() => {
    setlangData(data);
  });

  return (
    <div className="bg-[#1D1C1C] font-primary py-[80px] ">
      <div className="bg-contact-img max-w-max flex mx-auto justify-center h-4 sm:h-8">
        <h1 className="text-center  text-white text-24 sm:text-[42px]">
          Translator registration
        </h1>
      </div>
      <div className="stepS flex justify-center mx-auto pt-16 items-center gap-x-4 px-8 sm:px-4 mb-20">
        <div
          className="border rounded-full border-white text-white px-2 cursor-pointer relative bg-[#133a8d] w-7 h-7 text-center"
          // onClickCapture={() => setCurrentStep(1)}
        >
          1
          <p className="text-lg text-center text-white absolute -left-5 top-7">
            personal info
          </p>
        </div>
        <div className="w-56 border-b border-white"></div>
        <div
          // onClickCapture={() => setCurrentStep(2)}
          className={`border rounded-full  border-white text-white px-2 cursor-pointer relative w-7 h-7 text-center ${
            currentStep > 1 ? "bg-[#133a8d]" : ""
          }`}
        >
          2
          <p className="text-lg text-center text-white absolute -left-5 top-7">
            Service info
          </p>
        </div>
      </div>
      <p className="text-center text-white text-xl py-5 px-4">
        Register here for free, and we'll send you an email when we have a job
        waiting for you or{" "}
        <Link to="/user/auth/login" className="text-lightBlue font-semibold">
          log in
        </Link>
      </p>
      <p className="text-center text-white text-xl py-5 px-4 max-w-5xl mx-auto">
      Short on time to review the client's source text? Try the Tomedes <Link to="/tools/pre-translation " className="text-lightBlue font-semibold">Pre-Translation Toolkit</Link> for quick insights, including key terms, domain, and complexity.{" "}
        
      </p>
      {currentStep == 1 ? (
        <FirstStepAccount
          currentStep={currentStep}
          // userData={userData}
          setCurrentStep={setCurrentStep}
          // setCreateAccountData={setCreateAccountData}
          // createAccountData={createAccountData}
          // setUserData={setUserData}
        />
      ) : (
        ""
      )}
      {currentStep == 2 ? (
        <SecondStepAccount
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          // setCreateAccountData={setCreateAccountData}
          // setUserData={setUserData}
          // userData={userData}
          data={langData}
          // createAccountData={createAccountData}
        />
      ) : (
        ""
      )}

      <div className="w-11/12 sm:w-10/12 mx-auto max-w-4xl mt-20">
        <div className="bg-contact-img max-w-max  h-4 sm:h-8 mb-20">
          <h2 className="text-white text-24 sm:text-[42px]">
            Frequently Asked Questions
          </h2>
        </div>
        <div>
          <div className="flex items-center gap-2 mb-3">
            <img
              src="https://skins.tomedes.com/frontend/images/question_icon.svg"
              alt=""
              className="w-12 h-12"
            />
            <p className="text-lg text-white font-bold">
              What's in Tomedes for me?
            </p>
          </div>
          <div className="text-lg text-white font-light leading-5 pl-[56px] mb-10">
            <p>
              We know that you prefer to provide translation services in a
              marketplace that lets you select your projects and your rates. We
              also know that you don't like to deal with many clients.
            </p>
            <p>
              We will provide you with a single marketplace and one secure
              source for payment - Tomedes When you register with Tomedes, you
              will be able to choose everything:
            </p>
            <ul className="list-disc pl-8 my-3 flex flex-col gap-1">
              <li>
                Money - You will decide how much you are ready to be paid for
                each job.
              </li>
              <li>Time - You will decide when to work</li>
              <li>
                Jobs - You will decide which translation jobs you want to work
                on
              </li>
            </ul>
            <p>
              <Link
                to="/user/provider/createaccount"
                className="text-lightBlue"
              >
                Register for free
              </Link>{" "}
              , and we'll send you an email when we have a job waiting for you
            </p>
          </div>

          <div className="flex items-center gap-2 mb-3">
            <img
              src="https://skins.tomedes.com/frontend/images/question_icon.svg"
              alt=""
              className="w-12 h-12"
            />
            <p className="text-lg text-white font-bold">
              When will I get paid?
            </p>
          </div>
          <div className="text-lg text-white font-light leading-5 pl-[56px] flex flex-col gap-5 mb-10">
            <p>
              Payments are processed at the end of each month, 30 days after the
              completion of projects. For example, if you complete a project in
              July, you will receive payment by the end of August.
            </p>
            <p>Here’s how the payment process works:</p>
            <ol className="list-decimal list-inside md:ml-6 mt-4" type="1">
              <li>
                Projects delivered and approved in the previous month can be
                invoiced on the Vendor Portal.
              </li>
              <li className="my-8">
                If your invoice is approved on time, you will receive payment
                between the 20th and 30th of the current month.
              </li>
              <li>
                Ensure your payment details in the Profile section of the Vendor
                Portal are complete and accurate to avoid any delays. Only
                approved invoices with complete payment details will be paid on
                time.
              </li>
            </ol>
          </div>
          <div className="flex items-center gap-2 mb-3">
            <img
              src="https://skins.tomedes.com/frontend/images/question_icon.svg"
              alt=""
              className="w-12 h-12"
            />
            <p className="text-lg text-white font-bold">How do I get paid?</p>
          </div>
          <div className="text-lg text-white font-light leading-5 pl-[56px] flex flex-col gap-5 mb-10">
            <p>
              Invoices should be generated within the Vendor Portal only. Login
              details are provided upon completion of the registration process.
              The vendor is required to submit a single invoice on or before the
              10th of each month that includes all projects completed in the
              previous month.
            </p>
          </div>
          <div className="text-base text-white font-light leading-5 pl-[56px] flex flex-col gap-5 mb-10">
            <p>Payment Methods:</p>
            <p>a. PayPal Tomedes </p>
            <p>b. Bank Transfer</p>
            <p>c. Payoneer </p>

            <p className="mt-12">
              <span className="text-lg">IMPORTANT:</span> Please note that any
              payment method other than Paypal needs to be pre-authorized by our
              Finance Department. For further information, please contact
              finance@tomedes.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAccountNew;
